<template>
  <div v-loading="loading">
    <div class="min-h-screen app_page">
      <div class="container mx-auto">
        <div class="px-3 sm:px-4 py-6 sm:py-8">
          <!-- Show after user submits -->
          <div v-if="showConfirmationText">
            <ui-card class="ui_card overflow-visible">
              <ui-header :heading="$t('registration.your_place_is_reserved').toString()">
                <app-header-locale use-colored-flags class="block w-56" />
              </ui-header>
              <p>
                {{
                  $t("registration.confirmation_text", {
                    startDateTime: getFormattedSelectedSessionStartTime(),
                    location: getSelectedSessionLocation(),
                    title: `'${courseEvent.title}'`,
                  })
                }}
              </p>
              <br />
              <p>
                {{
                  $t("registration.soon_receive_email")
                }}
              </p>
            </ui-card>
          </div>

          <div v-else-if="courseEvent">
            <ui-card class="ui_card">
              <ui-header :heading="courseEvent.title">
                <app-header-locale use-colored-flags class="block w-56" />
              </ui-header>
              <RichTextField
                  class="mb-3"
                  v-model="courseEvent.description"
                  :hideBorder="true"
                  :showEditor="false">
              </RichTextField>
                <!-- Firstname + lastname -->
                <div class="flex flex-row flex-wrap gap-x-3 justify-between mt-6">
                  <ui-form-field
                    :label="$t('fields.name')"
                    class="flex-grow"
                    :minimize="true"
                  >
                    <el-input
                      type="text"
                      v-model="sessionParticipant.name"
                      :readonly="true"
                    ></el-input>
                  </ui-form-field>

                  <ui-form-field
                    :label="$t('fields.email')"
                    class="flex-grow"
                    :minimize="true"
                  >
                    <el-input
                      type="email"
                      v-model="sessionParticipant.email"
                      :readonly="true"
                    ></el-input>
                  </ui-form-field>

                  <ui-form-field
                    v-if="courseEvent.field1"
                    :label="courseEvent.field1"
                    class="flex-grow"
                    :minimize="true"
                  >
                    <el-input
                      type="text"
                      v-model="sessionParticipant.field1"
                      :readonly="true"
                    ></el-input>
                  </ui-form-field>

                  <ui-form-field
                    v-if="courseEvent.field2"
                    :label="courseEvent.field2"
                    class="flex-grow"
                    :minimize="true"
                  >
                    <el-input
                      type="text"
                      v-model="sessionParticipant.field2"
                      :readonly="true"
                    ></el-input>
                  </ui-form-field>
                </div>

              <CourseEventRegistrationList
                :session="courseEvent"
                @selectSession="(session) => handleSelectSession(session)"
                :heading="$tc('models.group', 2)"
                :sessions="sessions"
                :selectedSessionInput="findSessionById(this.sessionParticipant.session)"
              />

              <div class="flex mt-5 flex-row justify-end">
                <ui-link
                  type="primary"
                  :disabled="!hasChangedSession || sessionParticipant.session == null"
                  @click="handleSubmit()"
                >
                  {{ `${$t("actions.save")}` }}
                </ui-link>
              </div>
            </ui-card>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import UiHeader from "@/components/ui/UiHeader";
import UiLink from "@/components/ui/UiLink";
import UiCard from "@/components/ui/UiCard";
import UiFormField from "@/components/ui/UiFormField";
import CourseEventRegistrationList from "@/app/participant/components/CourseEventRegistrationList.vue";
import {getCourseEvent, getParticipant, updateParticipation} from "../api";
import AppHeaderLocale from "@/components/app/AppHeaderLocale";
import RichTextField from "@/components/form/RichTextField.vue";

export default {
  data() {
    return {
      sessionParticipant: {
        session: null,
        name: null,
        email: null,
        field1: null,
        field2: null,
      },
      courseEvent: null,
      sessions: [],
      showConfirmationText: false,

      // The original session id from session-participant object retrieved from server
      serverParticipantSession: null,
      loading: false
    };
  },

  components: {
    RichTextField,
    UiHeader,
    UiLink,
    UiCard,
    UiFormField,
    CourseEventRegistrationList,
    AppHeaderLocale,
  },

  computed: {
    hasChangedSession() {
      return this.serverParticipantSession !== this.sessionParticipant.session;
    }
  },

  methods: {
    getFormattedSelectedSessionStartTime() {
      const startDateTime = new Date(this.findSessionById(this.sessionParticipant.session).startDateTime);
      const options = {
        weekday: "long",
        year: "numeric",
        month: "long",
        day: "numeric",
        hour: "2-digit",
        minute: "2-digit",
      };
      return startDateTime.toLocaleString(this.$i18n.locale, options);
    },
    getSelectedSessionLocation() {
      return this.findSessionById(this.sessionParticipant.session).location;
    },
    findSessionById(id) {
      return this.sessions.find((s) => s.id === id);
    },
    handleSelectSession(session) {
      this.sessionParticipant.session = session != null ? session.id : null;
    },

    async handleSubmit() {
      if (this.hasChangedSession) {
        this.loading = true;
        const response = await updateParticipation(this.$route.params.participantId, this.sessionParticipant);
        if (response && response.status === 200) {
          this.showConfirmationText = true;
        }

        // Showing success status if API returned status-code 200
        if (response.status === 200)
          this.$message({ message: this.$t("server_feedback.status_200_saved").toString(), type: "success" });

        this.loading = false;
      }
    },

    async getCourseEvent() {
      this.loading = true;
      const courseEvent = await getCourseEvent(this.$route.params.id);
      this.sessionParticipant = await getParticipant(this.$route.params.participantId);
      this.serverParticipantSession = this.sessionParticipant.session;
      this.courseEvent = courseEvent;
      this.sessions = courseEvent.sessions;
      this.loading = false;
    },
  },

  async created() {
    this.loading = true;
    await this.getCourseEvent();
    this.loading = false;
  },
};
</script>

<style scoped>
/** Fixing wrong margin on p, and showing lists */
:deep(.ProseMirror) p {
  margin-bottom: 0px;
}
:deep(.ProseMirror) ol {
  list-style-type: decimal;
  margin-left: 20px;
}
:deep(.ProseMirror) ul {
  list-style-type: disc;
  margin-left: 20px;
}

.ui_card {
  margin-bottom: 10px;
}
.app_page {
  display: flex;
  flex-direction: column;
  width: 100%;
}
</style>
